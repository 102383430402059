<template>
  <div>
    <!-- TODO IF na poszczególne języki strony -->
    <home-page-de :products="{news, bestsellers, sales}" />
  </div>
</template>

<script>
import HomePageDe from '../components/HomePage/de'
export default {
  components: { HomePageDe },
  layout: 'withoutContainer',
  middleware: 'languages',
  async asyncData ({ app }) {
    const news = await app.$api.default.productsCarousel.news({ limit: 20 }).catch(() => { return null })
    const bestsellers = await app.$api.default.productsCarousel.bestsellers({ limit: 20 }).catch(() => { return null })
    const sales = await app.$api.default.productsCarousel.sales({ limit: 20 }).catch(() => { return null })
    return {
      news,
      bestsellers,
      sales
    }
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      title: '',
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        { hid: 'description', name: 'description', content: '' },
        ...i18nHead.meta
      ],
      link: [
        ...this.metaLink(i18nHead.link)
      ]
    }
  },
  created () {
    this.$store.dispatch('page/update', { name: 'breadcrumbs', data: null })
  }
}
</script>
