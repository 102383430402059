<template>
  <nuxt-link :to="localePath({ name: 'product', params: {productName: product.slug || toSlugTitle(product.description), productId: product.id} })">
    <div class="product-box">
      <div class="product-box-block">
        <div :class="['product-box-tags', { 'empty': !productTags.length }]">
          <div v-for="(tag, index) in productTags" :key="index" :class="['product-box-tag', tag.name]">
            {{ `#${tag.value} ${tag.translation}` }}
          </div>
        </div>
        <b-image
          v-if="product.mainImage"
          ratio="1by1"
          :src="getImageBySize(product.mainImage.file, 'medium').src"
          :webp-fallback="getImageBySize(product.mainImage.file, 'medium').webpFallback"
          class="skeleton-image-placeholder"
          lazy
          :alt="product.description.name"
        >
          <template #placeholder>
            <b-skeleton />
          </template>
        </b-image>
        <img v-else src="~/static/images/photo.jpg" alt="Photo">
        <div class="product-box-title">
          {{ product.description.name }}
        </div>
        <div v-if="product.offerAverageRate && product.offerAverageRate.rate" class="product-box-ratings">
          <b-rate
            v-model="productRating.value"
            disabled
          />
          <span>
            ({{ productRating.quantity }})
          </span>
        </div>
      </div>
      <div class="product-box-price">
        {{ $t('products.priceFrom',{price:toCurrency(product.summary.price, '',product.price.currency) }) }}
      </div>
    </div>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    productTags () {
      let flags = Object.entries(this.product.flags).filter(el => el[1])
      if (flags.length) {
        flags = flags.map((el) => {
          return this.convertFlag(el) || null
        })
      }

      return flags.filter(x => x)
    },
    productRating () {
      const value = this.product.offerAverageRate.rate ? this.product.offerAverageRate.rate : 0
      const quantity = this.product.offerAverageRate.count ? this.product.offerAverageRate.count : 0
      return { value, quantity }
    }
  },
  methods: {
    convertFlag (flag) {
      switch (flag[0]) {
        case 'bestsellerPosition_allTime':
          return { name: 'bestsellers', translation: this.$t('fields.bestsellers'), value: flag[1] }
        case 'newProduct_byTime':
          return { name: 'novelties', translation: this.$t('fields.novelties'), value: flag[1] }
        case 'sales':
          return { name: 'sale', translation: this.$t('fields.sale'), value: flag[1] }
      }
    }
  }
}
</script>
