<template>
  <div>
    <div class="hello">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h2>
              Willkommen bei Werbeartikel-Pro.de &#128075;
              <span>Einfacher Bestellvorgang, schneller Versand</span>
            </h2>
            <p>
              <b>Ihr Logo, Ihre Farben, Ihre Botschaft.</b> Machen Sie mit Alltagsgegenständen vom Kugelschreiber bis zum Feuerzeug auf sich aufmerksam und lassen Sie kinderleicht personalisierte Werbeartikel als Geschenk für Kunden oder Partner bedrucken.
            </p>
            <p>
              Wir bei Werbeartikel-Pro wählen unsere Produkte sorgfältig aus, bedrucken oder gravieren sie auf Wunsch professionell und versenden die Bestellung innerhalb kürzester Zeit. Nicht veredelte Artikel sind beispielsweise innerhalb von maximal 48 Stunden versandfertig. Sollten Sie vor, während oder nach dem Bestellvorgang Fragen haben, stehen wir Ihnen jederzeit telefonisch oder per Mail mit hilfreichen Antworten zur Verfügung.
            </p>
            <p>
              Bleiben Sie im Gespräch oder machen Sie auf sich aufmerksam, ohne Unsummen für Print-, Radio- und TV-Werbung auszugeben. Bei uns finden Sie günstige Werbeartikel, die wir nach Ihren Vorstellungen bedrucken und dabei beim Pricing stets transparent bleiben. Bei uns gibt es keine versteckten Kosten.
            </p>
          </div>
          <div class="column">
            <div class="homepage-video">
              <video ref="homepageVideo" width="100%" loop="true" autoplay muted>
                <source src="~/static/images/homepage.webm" type="video/webm">
              </video>
            </div>
          </div>
        </div>
        <div class="hello-overview">
          <div class="overview-item">
            <img src="~/static/images/homepage-schnelle.svg" alt="">
            <p>
              Schnelle Lieferzeiten
            </p>
          </div>
          <div class="overview-item">
            <img src="~/static/images/homepage-bestpreisgarantie.svg" alt="">
            <p>
              Bestpreisgarantie
            </p>
          </div>
          <div class="overview-item">
            <img src="~/static/images/homepage-approve.svg" alt="">
            <p>
              Keine versteckten Kosten
            </p>
          </div>
          <div class="overview-item">
            <img src="~/static/images/homepage-search.svg" alt="">
            <p>
              Kleine Mindestbestellmengen
            </p>
          </div>
          <div class="overview-item">
            <img src="~/static/images/homepage-support.svg" alt="">
            <p>
              Rundum-Support
            </p>
          </div>
          <div class="overview-item">
            <img src="~/static/images/homepage-gift.svg" alt="">
            <p>
              Und viele mehr
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="hello-comments">
      <div class="container">
        <h2>Was sagen unsere Kunden über uns?</h2>
        <div class="hello-summary">
          <div class="hello-summary-item">
            <h3>2135</h3>
            <span>Globale Kundenbewertungen</span>
          </div>
          <div class="hello-summary-item">
            <h3>93%</h3>
            <span>Zufriedenheit</span>
          </div>
          <div class="hello-summary-item">
            <h3>4,44</h3>
            <span>durchschnittliche Produktbewertung</span>
          </div>
          <div class="hello-summary-item">
            <h3>4,48</h3>
            <span>durchschnittliche Verkäuferbewertung</span>
          </div>
          <div class="hello-summary-item">
            <h3>4,76</h3>
            <span>Bewertung der Lieferzeit</span>
          </div>
          <div class="hello-summary-item">
            <h3>4,86</h3>
            <span>Bewertung der Aufdruckqualität</span>
          </div>
        </div>
      </div>
      <div class="hello-carousel">
        <client-only>
          <carousel :autoplay="3000" loop>
            <div v-for="index in 10" :key="index" class="p-4">
              <div class="hello-carousel-item">
                <div class="carousel-item-review">
                  <div class="carousel-item-review-photo">
                    <img src="~/static/images/guy1.png" alt="">
                  </div>
                  <div class="carousel-item-review-content">
                    <div class="review-content-header">
                      <h4>Adrian {{ index }}</h4>
                      <b-rate :value="5" disabled class="stars" />
                    </div>
                    <p>
                      Rewelacja! Najlepsze długopisy jakie miałem, bardzo smaczne
                    </p>
                  </div>
                </div>
                <div class="carousel-item-product">
                  <img src="~/static/images/product1.png" alt="">
                  <span>Notatnik filcowa okładka 160 stron</span>
                </div>
              </div>
            </div>
          </carousel>
        </client-only>
      </div>
    </div>
    <!-- Bestsellers -->
    <div v-if="products && products.bestsellers && products.bestsellers.length" class="hello-bestsellers">
      <div class="container">
        <client-only>
          <h2>
            {{ $t('fields.bestsellers') }}
          </h2>
          <carousel nav>
            <template #prev="{prevItem}">
              <div class="carousel-prev-button" @click="prevItem">
                <b-icon icon="chevron-left" />
              </div>
            </template>
            <template #next="{nextItem}">
              <div class="carousel-next-button" @click="nextItem">
                <b-icon icon="chevron-right" />
              </div>
            </template>
            <div v-for="product in products.bestsellers" :key="product.id" class="products-carousel-container">
              <product-box :product="product" />
            </div>
          </carousel>
        </client-only>
      </div>
    </div>
    <!-- Nowości -->
    <div v-if="products && products.news && products.news.length" class="hello-novelties">
      <div class="container">
        <client-only>
          <h2>
            {{ $t('fields.novelties') }}
          </h2>
          <carousel nav>
            <template #prev="{prevItem}">
              <div class="carousel-prev-button" @click="prevItem">
                <b-icon icon="chevron-left" />
              </div>
            </template>
            <template #next="{nextItem}">
              <div class="carousel-next-button" @click="nextItem">
                <b-icon icon="chevron-right" />
              </div>
            </template>
            <div v-for="product in products.news" :key="product.id" class="products-carousel-container">
              <product-box :product="product" />
            </div>
          </carousel>
        </client-only>
      </div>
    </div>
    <!-- Sale -->
    <div v-if="products && products.sales && products.sales.length" class="hello-sale">
      <div class="container">
        <client-only>
          <h2>
            {{ $t('fields.sale') }}
          </h2>
          <carousel nav>
            <template #prev="{prevItem}">
              <div class="carousel-prev-button" @click="prevItem">
                <b-icon icon="chevron-left" />
              </div>
            </template>
            <template #next="{nextItem}">
              <div class="carousel-next-button" @click="nextItem">
                <b-icon icon="chevron-right" />
              </div>
            </template>
            <div v-for="product in products.sales" :key="product.id" class="products-carousel-container">
              <product-box :product="product" />
            </div>
          </carousel>
        </client-only>
      </div>
    </div>
    <div class="homepage-description">
      <div class="container content">
        <h2>
          Individuelle Werbemittel bestellen
        </h2>
        <p>
          Neben der richtigen Botschaft an Ihre Kunden ist in erster Linie das Produkt entscheidend. Dank einem handverlesenen Sortiment bieten wir Ihnen hier stets das beste Preis-Leistungsverhältnis.
        </p>
        <p>
          Mit individualisierten Werbemitteln steigern Sie Ihren Bekanntheitsgrad und transportieren Ihre Botschaft. Bedrucken oder gravieren Sie Werbeartikel und nutzen Sie unsere Vorteile:
        </p>
        <ul>
          <li>
            Handverlesene Produkte
          </li>
          <li>
            Hervorragendes Preis-Leistungsverhältnis
          </li>
          <li>
            Werbeartikel in kleinen Mengen bestellen
          </li>
          <li>
            Sichere Bezahlung durch Paypal, Vorkasse, auf Rechnung oder per Überweisung
          </li>
          <li>
            Schnelle Lieferzeiten
          </li>
          <li>
            Keine Versandkosten innerhalb Deutschlands ab 250 € Nettowarenwert
          </li>
        </ul>
        <p>
          Wählen Sie aus einem großen Sortiment an Werbeartikeln das Produkt, das Ihre Nachricht nach außen tragen soll. Dabei können Sie sich zwischen diversen Kategorien entscheiden, die Sie je nach Branche oder Ausrichtung Ihres Unternehmens bestimmen und durch Druck bzw. Gravur personalisieren. Unsere Werbeartikel überzeugen durch höchste Qualität bei Material und Verarbeitung und stehen in Stückzahlen von 1 bis 100.000 zur Verfügung.
        </p>
        <p>
          Dank unserer Bestpreis-Garantie erhalten Sie darüber hinaus stets den günstigsten Preis. Finden Sie ein günstigeres Konkurrenzprodukt, erstatten wir Ihnen den Differenzbetrag plus weitere 5% der Netto-Warenwert-Summe zurück.
        </p>
        <p>
          Auch die Vorkosten, die das Bedrucken teilweise mit sich bringt, sind in der Preisangabe bereits enthalten. Ist ein Produkt mit „kostenlose Veredelung“ ausgezeichnet, entfallen die Vorkosten und es werden nur die tatsächlichen Druckkosten berechnet. Für diese Artikel ist allerdings eine Mindestbestellmenge notwendig.
        </p>
        <h2>
          Das Sortiment – Von Kugelschreiber bis Wanduhr
        </h2>
        <p>
          Möchten Sie Werbeartikel bedrucken, stellt sich neben dem Aufdruck die Frage, welches Produkt mit Ihrer Nachricht versehen werden soll. Dabei gilt: Je häufiger Ihre Botschaft wahrgenommen wird, desto besser. Aus diesem Grund sind handliche Alltagsprodukte als Werbegeschenk für Geschäftspartner und Kunden äußerst beliebt. Die Einteilung in mehrere Kategorien erleichtert Ihnen die Auswahl des richtigen Produkts. Stellen Sie sich also die Frage: Welche Werbeartikel möchte ich bedrucken lassen?
        </p>
        <h2>
          Schreibgeräte
        </h2>
        <p>
          Werbeartikel, wie Kugelschreiber, Füllfederhalter oder Bleistifte, zu bedrucken, ist besonders beliebt. Adaptieren Sie Ihre CI bei der Farbgebung der Stifte und personalisieren Sie auf diese Weise Kuli oder Füller. Die praktischen Accessoires glänzen nicht nur als Werbegeschenke, sondern ergänzen auch die Ausstattung Ihrer Mitarbeiter.
        </p>
        <p>
          Versehen Sie den Empfangsbereich, Arbeitsplätze oder Aufenthaltsräume mit bedruckten Werbekugelschreibern – auch mit Gravur – und legen Sie Ihre Werbebotschaft an prominenten Orten aus. Gerade im Außendienst hat sich diese Art von Werbemittel mit Druck oder Gravur bewährt – und auch im Büro machen sich Schreibgeräte mit Logo stets gut.
        </p>
        <p>
          UV-resistent, langlebig, hochwertig – Jetzt Kugelschreiber individuell bedrucken!
        </p>
        <p>
          Auch die unterschiedlichen Materialien der Werbeartikel sind kein Problem. Für jedes Produkt gibt es bei uns das entsprechende Druck- und Gravurverfahren, um Ihren Wünschen zu entsprechen. Egal, ob das Produkt aus Holz, Kunststoff oder Metall ist. Mit dem richtigen Druck oder einer Lasergravur werden Werbeartikel wie Kugelschreiber und andere Schreibgeräte perfekt zur eigenen Promotion genutzt.
        </p>
        <p>
          Bestellen Sie einzelne Stifte oder kaufen Sie ein komplettes Set und lassen Sie dieses mit Ihrem Logo bedrucken. Je nach Auswahl liefern wir bereits ab einer Bestellmenge von nur einem Stück – eine Mindestbestellmenge gibt es bei den meisten Produkten nicht. Profitieren Sie bei Werbeartikel-Pro von den günstigsten Preisen auf dem Markt, wenn es um das Bedrucken Ihrer Werbekugelschreiber geht.
        </p>
        <p>
          Wählen Sie aus verschiedenen Designs und Materialien, um den optimalen individualisierten Werbeartikel zu erhalten. Dabei können Sie neben den klassischen Schreibgeräten auch Touchpens bedrucken, die für Tablets, Smartphones oder jedes andere Gerät mit Touchscreen geeignet sind.
        </p>
        <h2>
          Promotion- und Streuartikel
        </h2>
        <p>
          Streuartikel – auch Give Aways genannt – finden als Werbeartikel besonders auf großen Events und Messen Anklang. Kaum eine Gelegenheit bietet eine bessere Möglichkeit, um Ihr Unternehmen mit bedruckten Werbeartikeln zu promoten. Unter die Kategorie Streuartikel fällt dabei eine mannigfaltige Auswahl an Produkten, darunter:
        </p>
        <ul>
          <li>Schlüsselbänder</li>
          <li>Schlüsselanhänger</li>
          <li>Feuerzeuge</li>
          <li>Produkte für Kinder</li>
          <li>Fan-Artikel</li>
          <li>Flaschenöffner</li>
        </ul>
        <p>
          Insbesondere Schlüsselanhänger und Schlüsselbänder sind beliebte Give Aways, die sich mit den verschiedensten Logos und Schriften bedrucken lassen.
        </p>
        <p>
          Darüber hinaus sind praktische Werbeartikel wie Haftnotizen inklusive Umschlag oder Armbänder sowie viele weitere alltägliche Produkte im Angebot, die Sie ebenfalls mit Ihrem Corporate Design bedrucken können.
        </p>
        <p>
          Je nach Material wählen Sie zwischen verschiedenen Drucktechniken oder einer Gravur mit moderner Laser-Technik.
        </p>
        <p>
          Viele Werbeartikel für Kinder – wie beispielsweise Teddybären und andere Plüschtiere – werden im Siebdruck-Verfahren beschriftet und tragen Ihr Logo oder eine entsprechende Nachricht als Werbung auf der Brust.
        </p>
        <p>
          Unsere Schlüsselbänder sind Made in Europe und in vielen Farben erhältlich. Die praktischen Werbeartikel sind in Full-Color Druck erhältlich, sodass eine nahezu uneingeschränkte Anzahl an Farben zur Verfügung steht. Beim Bedrucken werden die gewünschten Farben in das Material eingedampft. Der Druck ist dadurch sehr widerstandsfähig gegen Einflüsse von außen wie UV-Strahlung und auch die Waschmaschine kann ihm nichts anhaben.
        </p>
        <p>
          Verschenken Sie Werbeartikel in Form von Schlüsselbändern oder Schlüsselanhängern und dem Träger wird Ihre Firma stets im Gedächtnis bleiben.
        </p>
        <h2>
          Werbeartikel fürs Büro
        </h2>
        <p>
          Zusätzlich zu nützlichen Schreibgeräten, können Sie weitere Büroartikel zu Werbezwecken bedrucken lassen. Vom Notizbuch über Lineal bis zum Visitenkarten-Etui ist in dieser Kategorie alles geboten.
        </p>
        <p>
          Unternehmer wie Mitarbeiter aus technischen Branchen benötigen beispielsweise häufig ein Lineal, das Sie bei uns ebenfalls mit ihrer Botschaft bedrucken lassen können. Aber auch das personalisierte Notizheft ist ein passendes Präsent für Kunden und Mitarbeiter.
        </p>
        <p>
          Werten Sie Ihr Büro auf und leben Sie Ihre Corporate Identity vor allem auch im eigenen Haus.
        </p>
        <p>
          Werbeartikel sind nicht nur für Kunden gedacht. Auch Ihre Mitarbeiter sollen sich mit dem Unternehmen identifizieren und freuen sich über kleine Geschenke, die mit dem Logo ihrer Firma bedruckt sind. Dies stärkt das Gemeinschaftsgefühl und den Zusammenhalt innerhalb des Unternehmens.
        </p>
        <h2>
          Werbeartikel für Zuhause
        </h2>
        <p>
          Werbemittel können indes praktische Utensilien fürs Zuhause sein. Bleiben Sie im Gespräch, indem Sie nicht nur Kugelschreiber und Notizbücher bedrucken, sondern auch geschmackvolle Messer-Sets, raffinierte Salz- und Pfeffermühlen oder auch einen professionellen Korkenzieher bzw. ein komplettes, edles Weinset in der dazugehörigen Holzbox.
        </p>
        <p>
          Bedrucken Sie die Accessoires mit einem markanten, einprägsamen Slogan oder präsentieren Sie das Logo Ihres Unternehmens bei kleineren Artikeln auf ansprechenden Produkten für den Hausgebrauch.
        </p>
        <p>
          Bedrucken Sie nicht nur Ihre Geschenke sondern auch die Verpackung und gestalten Sie so das perfekte Präsent.
        </p>
        <p>
          Um die Werbeartikel angemessen zu verpacken, finden Sie bei uns auch die passenden Geschenkbeutel. Diese bestehen meist aus Tüll, sind in mehreren Farben erhältlich und können ebenfalls bedruckt werden. Per Tampondruck wird Ihre Botschaft somit auch auf den Geschenkbeuteln hochwertig und einprägsam übermittelt.
        </p>
        <h2>
          Werkzeug und Nützliches
        </h2>
        <p>
          Ob Eiskratzer, Maßband oder Taschenlampe: Gewisse Dinge sollte man immer parat haben. Warum also nicht auch diese nützlichen Produkte des Alltags bedrucken und personalisieren? Hier stehen – je nach Material – Siebdruck, Tampondruck oder die Lasergravur zur Verfügung, um Ihre Werbung auf Kunststoff, Polyester, Aluminium, Holz oder einem anderen Material zu verewigen.
        </p>
        <p>
          Auch Kleine Mindestbestellmengen bedrucken wir für Sie ohne Weiteres.
        </p>
        <p>
          Da unserer Artikel ohne Mindestbestellmenge auskommen, sind auch kleine Stückzahlen möglich. Entscheiden Sie sich aus einer großen Auswahl an Produkten in verschiedenen Farben und aus den unterschiedlichsten Materialien. Sie wählen aus, wir bedrucken.
        </p>
        <h2>
          Elektronik
        </h2>
        <p>
          Elektronische Gadgets sind ebenfalls gern gesehene Werbegeschenke. Bedrucken Sie Taschenrechner, Uhren, USB-Sticks oder nützliche Powerbanks mit Ihrem Logo und unterstreichen Sie die moderne Ausrichtung Ihres Unternehmens. Auch USB-Lampen oder praktische Haltevorrichtungen für Smartphone oder Tablet können von uns mühelos bedruckt werden.
        </p>
        <p>
          In der heutigen Zeit haben viele Menschen mindestens ein elektronisches Hilfsmittel ständig an ihrer Seite. Bleiben Sie mit diesem Werbemittel im Blickfeld Ihrer Kunden und machen Sie ihnen gleichsam Freude.
        </p>
        <h2>
          Reisen und Erholung
        </h2>
        <p>
          Auf Reisen gibt es viele Gegenstände, die einem das Leben erleichtern. Nutzen Sie diese Möglichkeit, um bei Ihren Kunden und Geschäftspartnern auch in der Freizeit stets präsent zu sein.
        </p>
        <p>
          Neben Taschen mit viel Stauraum und in diversen Designs stehen hier modische Baseball-Caps, praktische Gepäckanhänger, Isolierflaschen sowie Reisespiele zur Verfügung. Highlights sind dabei robuste Skipass-Halter mit Karabiner sowie Regenschirme in mehreren Farben, die eine besonders große Fläche zum Bedrucken bieten.
        </p>
        <p>
          Neben Sieb- und Tampondruck sowie Lasergravur steht auch der Transferdruck zur Verfügung, der bei Produkten aus Porzellan zum Einsatz kommt. In diesem Fall warten stilvolle Tassen, die schon am Morgen beim ersten Kaffee mit Ihrer Botschaft werben können.
        </p>
        <h2>
          Werbeartikel aus Leder
        </h2>
        <p>
          Hochwertige Lederartikel vermitteln Stil und Klasse. Eine Veredelung durch professionelle Stanztechnik ermöglicht Ihnen auch hier personalisierte Produkte, die Sie als Werbegeschenke an Kunden, Mitarbeiter und Geschäftspartner verteilen können.
        </p>
        <p>
          Neben Etuis und Federtaschen finden Sie in dieser Kategorie auch Geldbörsen oder edle Visitenkartenhalter. Bedrucken Sie die Werbeartikel aus Leder mit Ihrem Logo und bleiben Sie so im Gedächtnis.
        </p>
        <h2>
          Das Druckverfahren – witterungsbeständig, farbecht, hochwertig
        </h2>
        <p>
          Das Verfahren, mit dem wir Ihre Werbeartikel bedrucken, variiert je nach Material des Produkts. Da nicht alle Oberflächen gleich bedruckt werden können, verwenden wir für jeden Artikel stets das beste Verfahren und erzeugen auf diese Weise langlebige und hochwertige Drucke.
        </p>
        <p>
          Wir bedrucken Ihre Werbemittel nachhaltig, professionell und günstig.
        </p>
        <p>
          Unabhängig von der Stückzahl achten wir auf die höchste Qualität beim Druck und veredeln Werbegeschenke und Give Aways mit Ihrer Botschaft.
        </p>
        <p>
          Bei jedem Produkt besteht die Möglichkeit, es durch Bedrucken oder eine Gravur zu veredeln. Wenige Klicks genügen und schon bald können Sie Ihre Lieferung in Empfang nehmen. Für Bestellmengen ab 250 Euro Warenwert entfallen sogar die Versandkosten. Wählen Sie jetzt Ihre persönlichen Werbemittel und begeistern Sie Kunden, Freunde und Angestellte mit praktischen Alltagsgegenständen.
        </p>
        <p>
          Bestellen Sie unsere günstigen Werbeartikel in der gewünschten Menge und sparen Sie bares Geld durch unsere speziellen Sets und Angebote.
        </p>
        <h2>
          Und so geht’s – Werbeartikel bedrucken und kaufen in 3 Schritten
        </h2>
        <p>
          Der Bestellvorgang bei Werbeartikel-Pro ist nicht nur schnell, sondern auch denkbar einfach. Dank der durchdachten Auswahlmöglichkeiten finden Sie im Handumdrehen die gesuchten Artikel und können das Sortiment nach Eigenschaften wie Material, Farbe oder möglichen Druckverfahren filtern. Der eigentliche Bestellvorgang teilt sich nach der Wahl des Produkts in die folgenden drei Schritte auf:
        </p>
        <ul>
          <li>Upload von Bildern und Schriften</li>
          <li>Überprüfung der Bestellung</li>
          <li>Eingabe von Rechnungsdaten und Auswahl der Zahlungsweise</li>
        </ul>
        <p>
          In der Detailansicht können Sie – je nach Werbeartikel – zwischen verschiedenen Größen und Farben wählen und die Art der Veredelung bestimmen. Unterschiedliche Druckeigenschaften stehen hier abhängig vom Material zur Verfügung. Ein weiterer Klick befördert den Artikel in den Warenkorb und führt Sie direkt zum nächsten Schritt.
        </p>
        <p>
          Nun können Sie das Logo oder Bild hochladen, mit dem Sie Ihre Werbeartikel bedrucken möchten. Genügt ein Schriftzug als Veredelung, tragen Sie den Text einfach in das Kommentarfeld ein. Haben Sie keine Veredelung gewählt, wird dieser Schritt automatisch übersprungen. Alternativ können Sie uns Ihre Druckinformationen auch noch später per Mail zusenden. Mit komplizierten Systemen wie einem Aufdruckkonfigurator müssen Sie sich bei uns nicht auseinandersetzen, um Ihre Artikel nach Wunsch bedrucken zu lassen.
        </p>
        <p>
          TIPP: Die Auflösung von Bildern sollte mindestens 300 dpi betragen. Logos sollten indes mit mindestens 600 dpi und als Vektorgrafik hochgeladen werden.
        </p>
        <p>
          Nach dem Upload gelangen Sie zum Warenkorb und können Ihre Bestellung überprüfen. Ist alles in Ihrem Sinne, klicken Sie auf „Zur Kasse“: Hier bestimmen Sie Ihre Rechnungs- und Lieferanschrift sowie die gewünschte Zahlweise. Zur Wahl stehen dabei PayPal, Vorkasse, Sofortüberweisung sowie – nach Absprache – der Kauf auf Rechnung. Ein nervenaufreibendes Vor- und Zurück klicken ersparen Sie sich hier, da die Zusammenfassung der Bestellung bequem auf einer Seite verortet ist. So bestellen Sie Ihr Wunschprodukt mit einem einfachen Klick.
        </p>
        <p>
          Sollten Sie Ihre Werbeartikel per Vorkasse zahlen, winkt Ihnen an dieser Stelle ein Rabatt von 3% auf die Rechnungssumme. Mit dem Klick auf „Jetzt kaufen“ schließen Sie den Bestellvorgang ab und können sich auf die schnelle Lieferung Ihrer bedruckten oder unbedruckten Werbemittel freuen. Selbst bei großen Stückzahlen liefern wir schnell und zuverlässig – bei veredelten Produkten bereits innerhalb von 14 Tagen nach Druckfreigabe, unveredelte Artikel sind nach 3 bis 5 Tagen bei Ihnen. Andere Anbieter benötigen teilweise bis zu 3 Monate für Ihre Lieferung.
        </p>
        <h2>
          Handverlesene Werbeartikel – Lassen Sie jetzt Ihre Kunden-Geschenke bedrucken
        </h2>
        <p>
          Welche Art von Werbeartikel Sie auch beschriften möchten: Bei uns finden Sie vom Plüschtier über den Flaschenöffner bis zum USB-Stick stets die passenden Produkte.
        </p>
        <p>
          Hinterlassen Sie einen bleibenden Eindruck bei Geschäftspartnern und Kunden, indem Sie raffinierte Give Aways oder hochwertige Produkte für Büro und Haushalt mit Ihrem Logo bedrucken lassen. Wählen Sie aus verschiedenen Materialien und Drucktechniken, um das beste Ergebnis für Ihre Werbegeschenke zu erzielen.
        </p>
        <p>
          Dank des benutzerfreundlichen Bestellvorgangs und der flexibel wählbaren Stückzahlen wird Ihre Bestellung schnell und unkompliziert bearbeitet und in kürzester Zeit bei Ihnen. Bei Werbeartikel-Pro finden Sie das beste Preis-Leistungsverhältnis für Werbeartikel, die durch den richtigen Druck nach Ihrem Wunsch veredelt werden.
        </p>
        <p>
          Wählen Sie jetzt Ihr Wunschprodukt und lassen Sie hochwertige Werbeartikel bedrucken.
        </p>
        <div class="columns">
          <div class="column">
            <h2>
              Wieso kaufen Kunden am liebsten bei Werbeartikel-Pro.de?
            </h2>
            <ul>
              <li>Kein Mindestbestellwert</li>
              <li>Günstige 5,90 € Versandkostenpauschale</li>
              <li>Schnelle und flexible Reaktion auf Ihre Wünsche</li>
              <li>Optimales Preis/Leistungs- Verhältnis</li>
              <li>Sichere Zahlungsarten (Kauf auf Rechnung, Paypal, Banküberweisung)</li>
            </ul>
          </div>
          <div class="column">
            <h2>
              Bezahlarten
            </h2>
            <p>
              Wir bieten Ihnen vielfältige Zahlungsarten an. Suchen Sie sich einfach die für Sie optimale Zahlart aus.
            </p>
            <div class="payment-icons">
              <payments-icons />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBox from '~/marketplace-front-core/components/products/ProductBox'
import PaymentsIcons from '~/marketplace-front-core/components/shared/PaymentsIcons'

export default {
  components: {
    Carousel: () => { if (process.client) { return import('~/marketplace-front-core/components/layout/Carousel') } },
    ProductBox,
    PaymentsIcons
  },
  props: {
    products: {
      type: Object,
      default: null
    }
  }
}
</script>
